import React, {useCallback} from 'react';
import './PrivacyPolice.css';
import {useAppDispatch} from "../../store/hooks";
import {setModal} from "../../store/modalSlice";

export const PrivacyPolicy = () => {
	const dispatch = useAppDispatch();
	const cred = {
		app_name: '',
		site_name: '',
		site_email: ''
	};
	const closeModal = useCallback(()=>dispatch(setModal({modal:'privacyPolicy',status:false})), []);
	return (
		<>
			<div className='darkBG' onClick={closeModal} />
			<div className='centered'>
				<div className='modal'>
					<div className='modalHeader'>
						<h5 className='heading'>Privacy policy</h5>
					</div>
					<button className='closeBtn' onClick={closeModal}>
						X
					</button>
					<div className='modalContent'>
						<div>
							<h1>Privacy Policy.{cred.app_name} </h1>
							<div>
								<p>1. Introduction</p>
								<p>
									This Privacy Notice (the “Notice”) sets out
									how {cred.site_name} company (the “Company”
									“we” “us” or “our“) collects and process
									through the mobile application “
									{cred.app_name}” the Player's personal data.
									Personal data means any information from
									which the player (“you“, “your” or “Player“)
									can be directly or indirectly identified
									such as a name, location, online identifier,
									gender etc., (“Personal Data”). This Notice
									provides also the Players' rights related to
									their Personal Data.
								</p>
								<p>2. Acceptance and Consent</p>
								<p>
									You should read this Notice carefully before
									using the App. By using the App, or
									submitting a query to the {cred.site_name}{' '}
									Company via the Apps or other means,
									including telephone or mail, you accept this
									Notice. If you do not accept this Notice,
									please not use the App.
								</p>
								<p>3. Collect of Personal Data</p>
								<p>
									Your personal data is processing by company{' '}
									{cred.site_name} collects and process
									through the mobile application “
									{cred.app_name} (the “App“) the Player's
									personal data.
								</p>
								<p>4. Changes to the Privacy Policy</p>
								<p>
									If we make a material change to the Privacy
									Policy, we will provide you with appropriate
									notice and will seek your consent to the
									updated Privacy Policy in accordance with
									applicable legal requirements.
								</p>
								<p>5. Information Collection and Use</p>
								<p>
									Our primary goals in collecting and using
									information are to provide and improve the
									Service and to provide you with a better
									experience with the service.
								</p>
								<p>
									<strong>
										We process your personal data for the
										following specified purposes:
									</strong>
								</p>
								<ul>
									<li>Provision of Services</li>
									<li>Direct marketing</li>
									<li>Customer service quality monitoring</li>
									<li>Collect feedback from the market</li>
								</ul>
								<p>
									For a better experience, while using our
									Service, I may require you to provide us
									with certain personally identifiable
									information, including but not limited to
									Data about your account and in-game
									progress, your IP address and mobile device
									identifiers (such as your device ID,
									advertising ID), data about your device,
									such as device manufacturer, model,
									operating system and language, general
									location data like country or city name,
									data about your use of the Service, such as
									gameplay data and your interactions with
									other players inside the games.. The
									information that I request will be retained
									on your device and is not collected by me in
									any way.
								</p>
								<p>
									The app does use third party services that
									may collect information used to identify
									you.
								</p>
								<p>
									<strong>
										Link to privacy policy of third party
										service providers used by the app:
									</strong>
								</p>
								<ul>
									<li>
										<a
											href='https://play.google.com/about/play-terms/'
											target='_blank'
											rel='nofollow noopener noreferrer'
										>
											Google Play Services
										</a>
									</li>
									<li>
										<a
											href='https://firebase.google.com/support/privacy'
											target='_blank'
											rel='nofollow noopener noreferrer'
										>
											Firebase Analytics
										</a>
									</li>
									<li>
										<a
											href='https://www.facebook.com/privacy/policy'
											target='_blank'
											rel='nofollow noopener noreferrer'
										>
											Facebook
										</a>
									</li>
									<li>
										<a
											href='https://onesignal.com/privacy_policy'
											target='_blank'
											rel='nofollow noopener noreferrer'
										>
											OneSignal
										</a>
									</li>
									<li>
										<a
											href='https://policies.google.com/technologies/ads'
											target='_blank'
											rel='nofollow noopener noreferrer'
										>
											Google Ads
										</a>
									</li>
								</ul>
								<p>6. Which Personal Data we collect</p>
								<p>
									Our Service use “cookies”. Cookies are files
									with a small amount of data that are
									commonly used as anonymous unique
									identifiers. These are sent to your browser
									from the websites that you visit and are
									stored on your device's internal memory.
								</p>
								<p>7. What is the data retention period?</p>
								<p>
									The following retention periods and criteria
									will apply, unless a different period is
									required or permitted by applicable law or
									we have reasonable belief that it is
									necessary:{' '}
								</p>
								<ul>
									<li>
										data collected for the purposes of the
										above sections from is retained during
										the execution of the Apps GT&C. Plus the
										applicable retention periods set or
										allowed by specific laws, after the
										termination of the services and/or until
										you have required their deletion.
									</li>
									<li>
										In case of further queries you may
										contact us at the email address
										indicated in this Notice.
									</li>
								</ul>
								<p>
									8. What are the Players’ rights on Personal
									Data?{' '}
								</p>
								<ul>
									{' '}
									<strong>You can at any time:</strong>
									<li>
										obtain confirmation of the existence of
										personal data. This information is
										primarily provided on the Apps;
									</li>
									<li>
										know the origin, purposes and method of
										processing of the data and the logic
										applied to processing carried out
										through electronic tools.
									</li>
								</ul>
								<p>
									This information is provided in this Notice,
									as well as when using the features of the
									Apps. <br />
									<br />
									This service is provided via the customer
									service; You can revoke at any time, the
									data processing consent. <br />
									<br />
									The requests submitted by you shall be
									handled within 1 month weeks of receipt.
								</p>
								<p>9. Information sharing practices</p>
								<ul>
									{' '}
									<strong>
										We do not share any information we
										collect with third parties:
									</strong>
									<li>
										unless we believe in good faith that the
										disclosure of the information is
										required by any applicable law,
										regulation, court order, legal
										proceedings or governmental demand;
									</li>
									<li>
										or it is required to handle security
										breach or fraud investigations, or to
										enforce the terms of our Terms of Use.
									</li>
								</ul>
								<p>
									Otherwise we share your information with
									third parties with whom we have a business
									relationship such as advertisers, ad
									networks and analytics providers. The
									information shared with these third parties
									may be used for industry analysis, ad
									targeting, tracking ad conversions or
									demographic profiling, or other purposes
									related to providing the Services to you.
								</p>
								<p>
									Also the Service may contain links to other
									websites and online Services (“Linked
									Sites”). The fact that we link to a third
									party website or service is not an
									endorsement or representation of our
									affiliation with that third party, nor is it
									an endorsement of their privacy policies or
									practices. You acknowledge and agree that we
									are not responsible for the privacy
									practices of such Linked Sites, and this
									Privacy Policy does not apply to such Linked
									Sites. The operators of Linked Sites may
									collect information about you, and may use
									and disclose that information. We encourage
									you to review the privacy policies of the
									Linked Sites that you use.
								</p>
								<p>10. Age requirement</p>
								<p>
									You must be at least 13 years old to use the
									software. We will not knowingly collect
									personal information from a user who is
									younger than 13 years old and, if we have
									reason to believe that you are younger than
									13 years old, we will delete your
									information from our servers.
								</p>
								<p>11. Contacting Us</p>
								<p>
									If you have any questions about this Privacy
									Policy, please contact us at:{' '}
									<a
										href={`mailto:${cred.site_email}`}
										title='Send Email'
										target='_blank'
									>
										{cred.site_email}
									</a>
									.
								</p>
							</div>
						</div>
					</div>
					<div className='modalActions'></div>
				</div>
			</div>
		</>
	);
};
